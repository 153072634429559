import React from 'react'
import logoLight from '../../img/logo-light.svg'
import logoDark from '../../img/logo-dark.svg'
import ModeToggler from '../ModeToggler'
import SelectLanguage from '../SelectLanguage'
import select from '../../components/utils'
import menuTree from '../../data/menuTree'
import { FormattedMessage } from 'react-intl'
import Link from 'gatsby-link'

const Header = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                          navBarActiveClass: 'is-active',
                      })
                    : this.setState({
                          navBarActiveClass: '',
                      })
            }
        )
    }

    render() {
        const props = this.props
        const sel = select(props.langKey)
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <nav
                className="navbar is-font-secondary"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to={'/' + lang} className="navbar-logo">
                            <img
                                className="is-logo-dark"
                                src={logoDark}
                                alt="New Port"
                                width="140"
                            />
                            {/* <img
                                className="is-logo-light"
                                src={logoLight}
                                alt="New Port"
                            /> */}
                        </Link>
                          <Link
                                className="navbar-item has-text-weight-medium is-hidden-desktop is-size-5"
                                activeClassName="navbar-item-active"
                                to={lang + '/' + menuTree.contact[sel] + '/'}
                            >
                                <FormattedMessage id="header.contact" />
                            </Link>

                        {/* Hamburger menu */}
                        {/* <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span className="navbar-burger-span" />
                            <span className="navbar-burger-span" />
                            <span className="navbar-burger-span" />
                        </div> */}
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className="navbar-start"></div>
                        <div className="navbar-end">
                            {/* <Link
                                className="navbar-item has-text-weight-normal"
                                activeClassName="navbar-item-active"
                                to={lang + '/' + menuTree.services[sel] + '/'}
                            >
                                <FormattedMessage id="header.services" />
                            </Link> */}
                            {/* <Link
                                className="navbar-item has-text-weight-normal"
                                activeClassName="navbar-item-active"
                                to={lang + '/' + menuTree.brands[sel] + '/'}
                            >
                                <FormattedMessage id="header.brands" />
                            </Link> */}
                            <Link
                                className="navbar-item has-text-weight-medium is-size-5"
                                activeClassName="navbar-item-active"
                                to={lang + '/' + menuTree.contact[sel] + '/'}
                            >
                                <FormattedMessage id="header.contact" />
                            </Link>

                            {/* <SelectLanguage langs={props.langs} /> */}
                            {/* <div className="navbar-item has-text-weight-normal">
                                <ModeToggler />
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header
