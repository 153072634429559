module.exports = {
    Language: 'EN',
    tags: 'Tags',
    'tags.allTagsLink': 'Browse all tags',
    'tags.intro': 'Here you can find all the tags',
    'tags.blog.intro': 'Here you can find all the blog tags',
    'tags.pages.intro': 'Here you can find all the tags in the pages',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
    cookies:
        'Cookies help us deliver our services. By using our services, you agree to our use of cookies.',
    'cookies.policy': 'Cookies Policy',
    'cookies.accept': 'Accept',
    header: 'menu',
    'header.services': 'Services',
    'header.brands': 'Brands',
    'header.blog': 'Blog',
    'header.language': 'EN',
    'header.contact': 'Contact',
    subscribe: 'Subscribe',
    'subscribe.name': 'Name',
    'subscribe.email': 'Email',
    'subscribe.submit': 'Submit',
    home: 'Home',
    'home.hero.title':
        'Leading wholesaler company for electrical and ventilation products.',
    'home.hero.subtitle':
        'One of the leading wholesaler company for electrical and ventilation products in Georgia.',
    'home.hero.cta': "Let's Talk",
    'home.services.title': 'Services',
    'home.hire.title': 'Why you hire us?',
    'home.hire.subtitle':
        'We create possibilities that improve engagement and profitability for our customers. Every day we get on meaningful business challenges that improve your value!',
    'home.hero.vision': 'Operating in the Georgian market since 2011.',
    'home.hire.cta': 'Hire Us',
    'home.works.title': 'Our brands',
    'home.works.subtitle':
        'We are representing the following brands in Georgia.',
    'home.getwork.title': "Let's get to work!",
    'home.getwork.subtitle':
        'New project on the horizon? Our digital natives are waiting you!',
    services: 'Services',
    'services.roll.title': 'What we do',
    'services.roll.subtitle':
        'We build technologies that boosts productivity for businesses and simplifies customers’ lives.',
    'services.roll.cta': 'Learn More →',
    'services.hire.cta': 'Hire Us',
    blog: 'Blog',
    'blog.submit': 'Submit',
    'blog.roll.title': 'Founder Journey',
    'blog.roll.subtitle':
        'Read real experiences, challenges, and what the road to success looks like from the team behind New Port.',
    'blog.roll.subscribe.placeholder': 'Enter your email to subscribe',
    'blog-message': 'Last News',
    'blog.keep.reading': 'Keep reading →',
    'blog.time.read': 'min read',
    'blog.post.view': 'views',
    'blog.post.newsletter.first': 'Like What You Read? Join our subrcribers.',
    'blog.post.newsletter.second': 'Ready for more? Join our subrcribers.',
    'find-out-more': 'Find out more...',
    contact: 'Contact',
    'contact.title': 'We’re here to answer any questions you may have',
    'contact.name': 'Name',
    'contact.email': 'Email',
    'contact.message': 'Message',
    'contact.send': 'Send',
    not_found: '404 - Page Not Found',
    404: 'You are arrived to an unvailable page or you have missed the path...',
    work: 'Work',
    'work.roll.title': 'What we done',
    'work.roll.subtitle':
        'We build software that boosts productivity for businesses and simplifies customers’ lives.',
    'brands.case.cta': 'Catalog →',
    'work.hire.arrow.cta': 'Hire Us →',
    'work.post.location': 'Location',
    'work.post.date': 'Date',
    'work.post.timeline': 'Timeline',
    'work.post.team': 'Team',
    'work.post.features': 'Features',
    'work.post.services': 'Services',
    'work.post.tools': 'Tools',
    'work.post.hireus': 'Hire Us',
    footer: 'Footer',
    'footer.title.services': 'Services',
    'footer.title.company': 'Company',
    'footer.title.links': 'Links',
    'footer.title.follow': 'Follow Us',
    'footer.link.privacy': 'Privacy policy',
    'footer.link.cookie': 'Cookie policy',
    'footer.link.technologies': 'Technologies',
    'footer.link.blog': 'Blog',
    'footer.link.services': 'Services',
    'footer.link.contact': 'Contact',
    'footer.link.brands': 'Brands',
    copyright: '© 2024 New Port LLC',
}
