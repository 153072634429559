import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, injectIntl } from 'react-intl'
import menuTree from '../../data/menuTree'
import select from '../../components/utils'
import ScrollToTop from '../../components/ScrollToTop'
import logoDark from '../../img/logo-dark.svg'
import logoLight from '../../img/logo-light.svg'

class Footer extends React.Component {
    render() {
        const props = this.props
        const sel = select(props.langKey)
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <footer className="footer is-font-secondary">
                <div className="container">
                    <div className="columns is-vcentered is-mobile">
                        <div className="column is-6">
                            <div className="footer-logo">
                                <img
                                    className="is-logo-dark"
                                    src={logoDark}
                                    alt="New Port"
                                />
                                {/* <img
                                    className="is-logo-light is-128x128"
                                    src={logoLight}
                                    alt="New Port"
                                /> */}
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="is-right-scroll is-flex">
                                <ScrollToTop />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 columns is-multiline">
                        <div className="column is-4">
                            <p className="has-text-weight-medium has-text-footer-header-color">
                                <FormattedMessage id="footer.title.links" />
                            </p>
                             <ul>
                                <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.brands[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.brands" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-4">
                            <p className="has-text-weight-medium has-text-footer-header-color is-size-6">
                                <FormattedMessage id="footer.title.company" />
                            </p>
                            <ul>

                             <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.contact[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.contact" />
                                    </Link>
                                </li>
                           
                                {/* <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.cookie[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.cookie" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active has-underline-from-center"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.privacy[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.privacy" />
                                    </Link>
                                </li> */}
                               
                            </ul>
                        </div>

                        <div className="column is-12 social mt-6">
                            <div className="is-size-6 has-text-footer-header-color">
                                <FormattedMessage id="copyright" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default injectIntl(Footer)
