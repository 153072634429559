module.exports = {
    services: ['services', 'services'],
    brands: ['brands', 'brands'],
    blog: ['blog', 'blog'],
    contact: ['contact', 'contact'],
    privacy: ['privacy-policy', 'privacy-policy'],
    cookie: ['cookie-policy', 'cookie-policy'],
    jobs: ['jobs', 'jobs'],
    technologies: ['services/technologies', 'services/technologies'],
    digitalMarketing: [
        'services/digital-marketing',
        'services/cifruli-marketingi',
    ],
    webDevelopment: ['services/web-development', 'services/web-development'],
    appDevelopment: ['services/app-development', 'services/app-development'],
    blockchainDevelopment: [
        'services/blockchain-development',
        'services/blockchain-development',
    ],
    IoT: ['services/internet-of-things', 'services/nivtebis-interneti'],
}
